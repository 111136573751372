import { getAuthenticationMethods, getMe } from '@brainstud/academy-api';
import MethodsView from '@brainstud/account-panel/Views/Unauthenticated/MethodView/MethodView';
import { GetServerSideProps } from 'next';
import { AuthLayout } from '../../Layouts/AuthLayout/AuthLayout';
import { getRootDomain } from '../../Utils';
import { toLayout } from '../../Utils/toLayout';
import { NextPageWithLayout } from '../_app';

const LoginMethodsPage: NextPageWithLayout = () => <MethodsView />;
LoginMethodsPage.getLayout = toLayout(AuthLayout);

/**
 * This will check whether there is only one authentication method configured for this domain.
 * If so, it will redirect the page to that method automatically.
 *
 * __Note__: This does not work on localhost due to https restrictions.
 */
export const getServerSideProps: GetServerSideProps = async ({
  req,
  query,
}) => {
  const domainName = getRootDomain(req.headers.host);
  if (domainName && !query?.loggedOut) {
    const [me] = await getMe(domainName);
    const [methods] = await getAuthenticationMethods(
      { portal: me.portal?.().id },
      domainName
    );
    if (methods.length === 1) {
      const singleMethod = methods[0];
      const loginUrl = singleMethod.url || '/auth/login';
      const protocol =
        process.env.NODE_ENV === 'development' ? 'http://' : 'https://';
      const hasQueryString = loginUrl.includes('?');
      return {
        redirect: {
          destination: hasQueryString
            ? `${loginUrl}&redirect_uri=${protocol}${req.headers.host}/api/auth/token`
            : loginUrl,
          permanent: false,
        },
      };
    }
  }
  return {
    props: {
      headless: true,
    },
  };
};

export default LoginMethodsPage;
