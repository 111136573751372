import React, { useEffect, useMemo, useState } from 'react';
import { useAuthenticationMethods } from '@brainstud/academy-api';
import { Callout } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { AuthenticationMethod, Loading } from 'Components';
import { useReturnToValue } from 'Hooks/useReturnToValue';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import { SubHeader } from '../../../Layouts';
import styles from './MethodView.module.css';

const cx = classNames.bind(styles);

const MethodView = () => {
  const [t] = useTranslator();
  const [{ data: methods }, { isLoading }] = useAuthenticationMethods();

  const sortedMethod = useMemo(
    () =>
      methods.sort((a, b) => {
        if (a.type === 'SAML') {
          return -1;
        }
        if (b.type === 'SAML') {
          return 1;
        }
        return a.type < b.type ? -1 : 1;
      }),
    [methods]
  );

  const { query } = useRouter();
  useReturnToValue();

  const [redirectUrl, setRedirectUrl] = useState<string>();

  /**
   * Make sure we're doing this client side
   */
  useEffect(() => {
    setRedirectUrl(`${window.origin}/api/auth/token`);
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <div className={cx(styles.base)}>
      <SubHeader>
        <h1>{t('method.title')}</h1>
        <p>{t('method.subtitle')}</p>
      </SubHeader>
      <div className={cx(styles.methods)}>
        <Callout error margin="2rem 0" hidden={!query.error}>
          {query.error}
        </Callout>
        {sortedMethod.map((method) => (
          <AuthenticationMethod
            key={method.id}
            label={method.label}
            description={method.description}
            icon={method.icon}
            url={
              method.url?.includes('?')
                ? `${method.url}&redirect_uri=${redirectUrl}`
                : method.url
            }
            tooltip={method.tooltip}
          />
        ))}
        {process.env.NODE_ENV === 'development' && (
          <AuthenticationMethod
            label="Localhost login with oauth"
            description="Special method configured for development"
            url={`${process.env.NEXT_PUBLIC_DEV_OAUTH_URL}/oauth/authorize?client_id=${process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID}&response_type=code&redirect_uri=${process.env.NEXT_PUBLIC_OAUTH_REDIRECT_URL}`}
          />
        )}
      </div>
    </div>
  );
};

export default MethodView;
